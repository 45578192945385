import React,{useEffect, useState} from 'react'
import AliceCarousel, { Link } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Brands.css'
import logo1 from "../../images/logo1.JPG"
import logo3 from "../../images/logo3.JPG"
import logo4 from "../../images/logo4.JPG"
import logo5 from "../../images/logo5.JPG"
import logo6 from "../../images/logo6.JPG"
import logo7 from "../../images/logo7.PNG"
import logo8 from "../../images/logo8.JPG"
// import Glide from "@glidejs/glide"

const Brands = () => {
     const [logoImage, setlogoImage] =useState([{id:1,image:logo1},{id:3,image:logo3},{id:4,image:logo4},{id:5,image:logo5},
        {id:6,image:logo6},{id:7,image:logo7},{id:8,image:logo8}])

        const [items, setItems] = useState([]);

        useEffect(() => {
          const itemsArray = logoImage.map((url, index) => (
            <img key={index} src={url?.image} alt={`Image ${index + 1}`} className=" h-[200px] w-[200px] px-3 object-fill " />
          ));
          setItems(itemsArray);
        }, [logoImage]);
console.log(items)

    //     useEffect(() => {
    //       const slider2 = new Glide(".glide-02", {
    //        type: 'carousel',
    // startAt: 0,
    // perView: 7,
    // focusAt: 'center',
 
    // loop: true,
    //         classNames: {
    //           nav: {
    //             active: "[&>*]:bg-wuiSlate-700",
    //           },
    //         },
    //         breakpoints: {
    //           1024: {
    //             perView: 5,
    //           },
    //           480: {
    //             perView: 3,
    //           },
    //         },
    //       }).mount()
    //       return () => {
    //         slider2.destroy()
    //       }
    //     }, [])

  
  return (
    <>
<section className=' my-4 px-1 flex flex-col justify-center items-center text-center'>
  <div>
    <h2 className='text-2xl font-bold  py-2  text-hfsec-900'> We've collaborated with a variety of brands.</h2>
    <p className='text-lgmd:max-w-[85%]'>We've partnered with numerous brands in various collaborative endeavors.</p>
  </div>
</section>

<div className='px-3 pt-2 pb-2 w-full'>
<AliceCarousel items={items }
        autoPlay
        autoPlayInterval={3500}
        infinite
        responsive={{
          0: {
              items: 1,
          },
          1024: {
              items: 7,
              itemsFit: 'contain',
          }
        }} // Set responsive settings
        disableButtonsControls // Set responsive settings
        disableSlideInfo
        disableDotsControls
        autoWidth
         mouseTracking
         >
    </AliceCarousel>
</div>

{/* 
   <div className="glide-02 relative  px-3 pt-2 pb-2">
        
            <div className="overflow-hidden  "  data-glide-el="track">
       
              <ul className="whitespace-no-wrap flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex justify-center overflow-hidden p-0 image-container h-[150px]">
              
                {logoImage?.map( (ele, index) => {
                 return(
                <li className="box-shadow-xl shadow-gray-200" >
    
    <img
    className='object-fill w-full h-full  '
              key={index}
              src= {ele?.image}
              loading="lazy"
              alt="No Image "
           
            />
               
                </li>
                      )
    })}
                
              </ul>
            </div>
           
            <div
              className="absolute left-0 top-1/2 flex h-0 w-full items-center justify-between px-4 "
              data-glide-el="controls"
            >
              <button
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
                data-glide-dir="<"
                aria-label="prev slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <title>prev slide</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                  />
                </svg>
              </button>
              <button
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
                data-glide-dir=">"
                aria-label="next slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <title>next slide</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </div> */}
      </>
  )
}

export default Brands

import React from 'react'
import swapnil_img from "../../images/swapnil_img.jpg"
import mayur_img from "../../images/mayur_img.JPG"

const Founders = () => {
  return (
    <>
    <div className=' my-3 py-2'>
<section className='px-1 flex flex-col justify-center items-center text-center'>
  <div>
    <h2 className='text-2xl  font-bold  py-2  text-hfsec-900'>Meet the Founders</h2>
    <p className='text-sm md:max-w-[85%]'>Who made it all possible.</p>
  </div>
</section>
      <section className=' my-2 flex flex-wrap justify-center' >

<div className=" mx-2 py-2 rounded hover:border-2 hover:border-hfsec-900  max-w-[350px] border-gray flex flex-col justify-start items-center"> 
   <div className="my-3 px-4 pb-2">
    <img className="rounded-full h-[175px] w-[175px]" src={swapnil_img}></img>
    </div>
    <div className="px-2 text-center ">
        <h2 className="text-sm"> Swapnil Valkunde </h2>
        <h2 className="text-gray-600 pb-3 text-sm"> Engineering Precision Driving Business Growth
</h2>
    </div>
    <p className="text-[12px] h-full text-justify px-2">
    Swapnil Valkunde, co-founder of Glow & Rise Media Pvt. Ltd., is an entrepreneur with a mechanical engineering background from RIT College. His journey into entrepreneurship stems from a passion for innovation and problem-solving. With a strong foundation in mechanical engineering, Swapnil brings a unique perspective to Glow & Rise Media Pvt. Ltd., emphasizing precision and efficiency in business operations.<br></br>

Under Swapnil's leadership, Glow & Rise Media Pvt. Ltd. thrives as a dynamic force in digital marketing. His technical expertise, coupled with entrepreneurial acumen, fuels the company's growth trajectory. Swapnil's commitment to excellence and forward-thinking approach position Glow & Rise Media Pvt. Ltd. as an industry leader, driving innovation and delivering impactful results for clients globally.

    </p>
    </div>

    <div className=" mx-2 py-2 rounded hover:border-2 hover:border-hfsec-900  max-w-[350px] border-gray flex flex-col justify-start items-center"> 
   <div className="my-3 px-4 pb-2">
    <img className="rounded-full h-[175px] w-[175px]" src={mayur_img}></img>
     </div>
    <div className="px-2 text-center">
        <h2 className="text-sm"> Mayur Valkunde</h2>
        <h2 className="text-gray-600 pb-3 text-sm"> A Journey of Innovation in Marketing </h2>
    </div>
    <h3 className="text-[12px] h-full text-justify px-2">
    Mayur's journey in marketing began with his pursuit of engineering at KIT College, where his fascination with digital platforms sparked his interest in leveraging technology for business growth. After completing his MBA with a specialization in Marketing and Human Resources from Singhgad College, Mayur embarked on a mission to revolutionize the social media and influencer marketing landscape.
<br></br>
His passion for marketing and technology led him to establish Glow & Rise Media Pvt. Ltd., where he and his team harness the power of social media and influencer networks to drive impactful marketing campaigns for clients across diverse industries. Mayur's strategic vision and dedication have propelled Glow & Rise Media Pvt. Ltd. to the forefront of the industry, delivering exceptional results and solidifying its reputation as a leader in digital marketing.
    </h3>
    </div>
    {/* </section> */}
</section>
</div>
    </>
  )
}

export default Founders

import React from "react";

import { useState } from "react";
import glowriselogo from "../../images/logo_final.png"
import { Link, NavLink } from "react-router-dom";
import { FaTimes ,FaBars} from "react-icons/fa";
 
export function NavHeader() {

const [navbar, setNavbar] = useState(false);
 
  return (
    
      
            <nav className="w-full px-4 bg-hfsec-900 items-center shadow  sticky  top-0 z-50">
            {/* <nav className="w-full px-5 bg-[#9b0cbc]  items-center shadow  sticky  top-0 z-50"> */}
                <div className="justify-between  mx-auto lg:max-w-7xl md:items-center md:flex ">
                    <div>
                        <div className="flex items-center justify-between md:py-3  md:block">
                                <div className="display flex align-middle">

                                <img className="object-fill" width='35px' loading="lazy" height='35px' src={`${glowriselogo}`}></img> 
                            <Link to="/" className="text-yellow-100 hover:text-yellow-300 text-lg pt-2   no-underline ">
                              Glow and Rise
                            </Link>
                                </div>
                            <div className="md:hidden ">
                                <div
                                    className="py-3 text-gray-700 rounded-md outline-none border-none"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <FaTimes className="text-white text-xl"  />
                                        ) : (
                                        <FaBars className="text-white text-xl" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
               
                        <div
                            className={`flex justify-center items-center md:pt-2  md:block md:pb-0 md:mt-0 ${
                                navbar ? "block" : "hidden"
                            }`}
                        >
                            <ul className=" flex items-center justify-center  mb-2 md:mb-0 ">
                              
                                <li className="mx-2  text-lg">
                                <NavLink to="/" className="text-yellow-100 hover:text-yellow-300 ">Home</NavLink>
                                </li>
                                {/* <li className=" mx-2 no-underline  text-lg">
                                    <NavLink to="/about" className="text-yellow-100 hover:text-yellow-300 ">About</NavLink>
                                </li> */}
                                {/* <li className=" mx-2 no-underline hover:text-indigo-200  text-lg">
                               
                                    <Link to="/blog">Blog</Link>
                                </li> */}
                                {/* <li className=" mx-2  text-lg">
                                <NavLink to="/about" className="text-yellow-100 hover:text-yellow-300 ">Contact</NavLink>
                                </li> */}
                               {/* <li className="text-yellow-100 hover:text-yellow-300text-lg">
                               <Link to="/about">Login</Link>
                                </li> */}
                                {/* <li className="text-yellow-100 hover:text-yellow-300 text-lg">
                                    <a to="/">Logout</a>
                                </li>  */}

                                
                            </ul>

                        </div>
                 
                        
                </div>
            </nav>
 
    
  );
}
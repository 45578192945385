import React ,{useEffect,useState} from 'react';
import './CarouselComponent.css'
import AliceCarousel, { Link } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import gvideo6 from "../../videos/gvedio6.gif"
import gvideo7 from "../../videos/gvedio7.gif"
import gvideo8 from "../../videos/gvedio8.gif"
import gvideo10 from "../../videos/gvedio10.gif"
import gvideo11 from "../../videos/gvedio11.gif"
import gvideo12 from "../../videos/gvedio12.gif"
// import gvideo13 from "../../videos/gvedio13.gif"
import gvideo14 from "../../videos/gvedio14.gif"
import gvideo16 from "../../videos/gvedio16.gif"
import gvideo1 from "../../videos/gvedio1.gif"
import gvideo2 from "../../videos/gvedio2.gif"
import gvideo3 from "../../videos/gvedio3.gif"
import gvideo4 from "../../videos/gvedio4.gif"
import gvideo5 from "../../videos/gvedio5.gif"

import pgvideo6 from "../../videos/pgvedio6.png"
import pgvideo7 from "../../videos/pgvedio7.png"
import pgvideo8 from "../../videos/pgvedio8.png"
import pgvideo10 from "../../videos/pgvedio10.png"
import pgvideo11 from "../../videos/pgvedio11.png"
import pgvideo12 from "../../videos/pgvedio12.png"
// import gvideo13 from "../../videos/gvedio13.png"
import pgvideo14 from "../../videos/pgvedio14.png"
import pgvideo16 from "../../videos/pgvedio16.png"
import pgvideo1 from "../../videos/pgvedio1.png"
import pgvideo2 from "../../videos/pgvedio2.png"
import pgvideo3 from "../../videos/pgvedio3.png"
import pgvideo4 from "../../videos/pgvedio4.png"
import pgvideo5 from "../../videos/pgvedio5.png"
// import Glide from "@glidejs/glide"

const CarouselComponent = () => {


  // let [isLoaded,setisLoaded]=useState(false)    

  // let handleloading=()=>{
  //   console.log("handleloading Triggerd L61 ",isLoaded)
  //   setisLoaded(true)
  // }

  const [items, setItems] = useState([]);
  const [gifUrls, setgifUrls] =useState([{url:gvideo5, alt:pgvideo5},
    {url:gvideo6, alt:pgvideo6},{url:gvideo7, alt:pgvideo7},
    {url:gvideo8, alt:pgvideo8},{url:gvideo10, alt:pgvideo10},
    {url:gvideo11, alt:pgvideo11},{url:gvideo12, alt:pgvideo12},
   {url:gvideo14, alt:pgvideo14},{url:gvideo1, alt:pgvideo1},
   {url:gvideo2, alt:pgvideo2},{url:gvideo3, alt:pgvideo3},
   {url:gvideo4, alt:pgvideo4},
    {url:gvideo16, alt:pgvideo16}
  ])

  useEffect(() => {
    const itemsArray = gifUrls.map((gifurl, index) => (
      <img key={index} src={gifurl?.url} alt={`Image ${index + 1}`} className=" h-[400px] w-[250px]  px-3 object-fill " />
    ));
    setItems(itemsArray);
  }, [gifUrls]);

  // useEffect(() => {
  //   const slider = new Glide(".glide-01", {
  //     type: "carousel",
  //     // focusAt: "center",
  //     perView: 5,
  //     loop:true,
  //     autoplay: 5000,
  //     // animationDuration: 700,
  //     // gap: 24,
  //     classNames: {
  //       nav: {
  //         active: "[&>*]:bg-wuiSlate-700",
  //       },
  //     },
  //     breakpoints: {
  //       1024: {
  //         perView: 4,
  //       },
  //       480: {
  //         perView: 3,
  //       },
  //     },
  //   }).mount()
  //   return () => {
  //     slider.destroy()
  //   }
  // }, [])
  return (
    <>
    <div className='flex flex-col w-full bg-gray-800  px-3 pt-2 pb-2'>

<AliceCarousel items={items }
        autoPlay
        autoPlayInterval={5000}
        infinite
        responsive={{
          0: {
              items: 2,
          },
          1024: {
              items: 7,
              itemsFit: 'contain',
          }
        }} // Set responsive settings
        disableButtonsControls // Set responsive settings
        disableSlideInfo
        disableDotsControls
        autoWidth
         mouseTracking
         >

    </AliceCarousel>

         
          {/* <div className="glide-01 relative w-full bg-gray-800  px-3 pt-2 pb-2">
        
            <div className="overflow-hidden" data-glide-el="track">
           
              <ul className="whitespace-no-wrap flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex justify-center overflow-hidden p-0 image-container h-[225px] md:h-[70vh]">
              
                {gifUrls?.map( (ele, index) => {
                      console.log("ele.usl l206",ele.url)
                      return(
                <li className="imageCar border-2 hover:z-30 hover:shadow-gray-100 hover:scale-[130%] hover:border-4 hover:border-black shadow-sm shadow-gray-200" >
    
    <img
    className='object-cover w-full h-full  '
              key={index}
              src= {isLoaded? ele?.url: ele?.alt}
              alt="GIF"
              onMouseEnter={(e) => {
                return     e.target.src =isLoaded? ele?.url: ele?.alt
              }}
              onMouseLeave={(e) => {
                e.target.src = isLoaded? ele?.url: ele?.alt
              }}
              onLoad={()=>handleloading()}
            />
               
                </li>
                      )
    })}
                
              </ul>
            </div>
      
            <div
              className="absolute left-0 top-1/2 flex h-0 w-full items-center justify-between px-4 "
              data-glide-el="controls"
            >
              <button
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
                data-glide-dir="<"
                aria-label="prev slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <title>prev slide</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                  />
                </svg>
              </button>
              <button
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
                data-glide-dir=">"
                aria-label="next slide"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <title>next slide</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </div> */}
          </div>
     </>
   
  )
}

export default CarouselComponent

import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import { Routes ,Route} from 'react-router-dom';
import { NavHeader } from './Components/Navbar/NavHeader'
import FootBar from './Components/Footer/FootBar'


function App() {
  return (
    <div >
      {/* className="App overflow-x-hidden" */}
      <NavHeader></NavHeader>
       <Routes>

    <Route exact path='/' element={  <Home></Home>}></Route>
    {/* <Route exact path='/learnCourse' element={<LearnCourse/>}></Route>
    <Route exact path='/practiceCourse' element={<PracticeCourse/>}></Route>
    <Route exact path='/login' element={<Login/>}></Route>
    <Route exact path='/register' element={<Register/>}></Route> */}
<Route exact path='/about' element={< About />}></Route>
{/* <Route exact path='/contact' element={< Contact />}></Route> */}
   
</Routes>
<FootBar></FootBar>
    </div>
  );
}

export default App;

import React, { useEffect,useRef,useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import logo1 from "../images/logo1.JPG"
import logo3 from "../images/logo3.JPG"
import logo4 from "../images/logo4.JPG"
import logo5 from "../images/logo5.JPG"
import logo6 from "../images/logo6.JPG"
import logo7 from "../images/logo7.PNG"
import logo8 from "../images/logo8.JPG"
import slogan from "../images/slogan.png"


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import Glide from "@glidejs/glide"
import Aos from 'aos';
import HomeFunction from '../Components/HomeComponents/HomeFunction';
import Brands from '../Components/HomeComponents/Brands';
import Platforms from '../Components/HomeComponents/Platforms';


import CarouselComponent from "../Components/HomeComponents/CarouselComponent"
import Founders from '../Components/AboutComponents/Founders';
// const CarouselComponent = React.lazy(() => import("../Components/HomeComponents/CarouselComponent"));
// const HomeFunction = React.lazy(() => import('../Components/HomeComponents/HomeFunction'));
// const Brands = React.lazy(() => import('../Components/HomeComponents/Brands'));
// const Platforms = React.lazy(() => import('../Components/HomeComponents/Platforms'));

const Home = () => {

  let logoImage=[{id:1,image:logo1},{id:3,image:logo3},{id:4,image:logo4},{id:5,image:logo5},
    {id:6,image:logo6},{id:7,image:logo7},{id:8,image:logo8},{id:9,image:logo4},{id:10,image:logo5}]

let [isLoaded,setisLoaded]=useState(false)

let handleloading=()=>{
  console.log("handleloading Triggerd L61 ",isLoaded)
  setisLoaded(true)
}
console.log("Onlading Triggerd L64 ",isLoaded)

Aos.init();
  const videoEl = useRef(null);
  const reactvideoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();

  }, []);


  
// useEffect(()=>{
//   const slider2 = new Glide(".glide-02", {
//     type: "carousel",
//     focusAt: "center",
//     perView: 7,
//     autoplay: 700,
//     // autoplay: true,
//     animationDuration: 800,
//     gap: 28,
//     classNames: {
//       nav: {
//         active: "[&>*]:bg-wuiSlate-700",
//       },
//     },
//     breakpoints: {
//       1500: {
//         perView: 6,
//       },
//       1200: {
//         perView: 4,
//       },
//       820: {
//         perView: 3,
//       },
//       490: {
//         perView: 2,
//         gap: 36,
//       },
//     },
   
//   }).mount()

//   return () => {
//     slider2.destroy()
//   }
// },[])


  return (
    <div>
 
 <div className='w-full flex justify-center items-center'>
 
 <CarouselComponent/>

 </div>


<Brands/>
 
 {/* <Suspense fallback={<div>Loading...</div>}>
  <Platforms/>
   </Suspense> */}





<section className=' my-3 px-1 flex flex-col justify-center items-center text-center'>
  <div>
    <h2 className='text-2xl font-bold  py-2  text-hfsec-900'>How Glow and Rise functions?</h2>
    <p className='text-lgmd:max-w-[85%]'>Delegate your content creation and harness the strength of community-generated content.</p>
  </div>
<HomeFunction></HomeFunction>
</section>

<Founders/>

{/* Slogan */}
<div className='w-full max-h-60' >
  <img src={slogan} alt="no Slogan image" className=' w-full max-h-60'  >
  </img>

</div>
{/* Ends Slogan */}

    </div>
  )
}

export default Home

import React from 'react'


const About = () => {
  return (
    <div >

      <section className='  my-3 px-1 flex flex-col justify-center items-center text-center'>
 
    <h2 className='text-4xl font-bold  py-2  text-pink-700'>  
About us </h2>
    <p className='text-lgmd:max-w-[85%]'>We are leading influencer marketing platform dedicated to connecting brands with influencers to create authentic and engaging.</p>

</section>
      <section className=' my-3 px-1  flex flex-col justify-center items-center text-center'>

    <h2 className='text-2xl font-bold  py-2 text-pink-700'>  
Our Story </h2>
    <p className='text-lg text-center md:max-w-[85%]'> <b>Need to Edit this dat </b> Need to Edit this data is to revolutionize the digital markiting by harnessing the power of social media influencers to drive the brand awareness, engagement and conversions</p>
   </section>


   <section className=' my-3 px-1  flex flex-col justify-center items-center text-center'>
    <h2 className='text-2xl font-bold py-2 text-pink-700'>  
Our Mission </h2>
    <p className='text-lg text-center md:max-w-[85%]'>Our Mission is to revolutionize the digital markiting by harnessing the power of social media influencers to drive the brand awareness, engagement and conversions</p>
 
</section>


<section className='flex flex-wrap justify-center my-5 py-2' >
{/* <section className="bg-[#343942] mx-1 py-8 p-4 flex flex-col"> */}
   
<div className=" mx-2 py-2 rounded hover:border-2 hover:border-pink-700  max-w-[350px] border-gray flex flex-col justify-start items-center"> 
   <div className="my-3 px-4 pb-2">
    <img className="rounded-full h-[175px] w-[175px]" src="https://media.licdn.com/dms/image/D4D03AQFCVvqnU5VRYg/profile-displayphoto-shrink_200_200/0/1702707062124?e=2147483647&v=beta&t=sYG4V6b4PchiZWbJeFjFFGm6DL1fyDLmbIzCG8FWfgQ"></img>
    </div>
    <div className="px-2">
        <h2 className="text-sm"> Swapnil Jadhav </h2>
        <h2 className="text-gray-600 pb-3 text-sm"> CO-Founder </h2>
    </div>
    <h3 className="text-sm h-full text-justify px-2">
    Perfectly known for its fabulous taste and food. Anywhere your hunger is satisfied.
     They give best fried chicken and burgers those are yummy.
    </h3>
    </div>

    <div className=" mx-2 py-2 rounded hover:border-2 hover:border-pink-700  max-w-[350px] border-gray flex flex-col justify-start items-center"> 
   <div className="my-3 px-4 pb-2">
    <img className="rounded-full h-[175px] w-[175px]" src="https://media.licdn.com/dms/image/D4D03AQFCVvqnU5VRYg/profile-displayphoto-shrink_200_200/0/1702707062124?e=2147483647&v=beta&t=sYG4V6b4PchiZWbJeFjFFGm6DL1fyDLmbIzCG8FWfgQ"></img>
    </div>
    <div className="px-2">
        <h2 className="text-sm"> Swapnil Jadhav </h2>
        <h2 className="text-gray-600 pb-3 text-sm"> CO-Founder </h2>
    </div>
    <h3 className="text-sm h-full text-justify px-2">
    Perfectly known for its fabulous taste and food. Anywhere your hunger is satisfied.
     They give best fried chicken and burgers those are yummy.
    </h3>
    </div>
    {/* </section> */}
</section>

    </div>
  )
}

export default About

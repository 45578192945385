import React,{useState} from 'react'
import page_2 from "../../images/homeFuncImage.jpeg"

const HomeFunction = () => {
   let [error,seterror]=useState(false)
// let handleError=()=>{
//     seterror(true)
// }
// let handleloading=()=>{
//     seterror(false)
// }

  return (
    <div>
       <div>
  <img
className={`my-2 object-fit w-full h-full  `}
          src= {page_2}// Set the static image as the initial source
          alt="No Image"
          loading="lazy"
        //   onError={handleError()}
        //   onLoad={()=>handleloading()}
        />

  </div>
    </div>
  )
}

export default HomeFunction
